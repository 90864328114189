import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Images
import ArrowRight from 'img/arrowright.inline.svg'

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-bottom: 1.75rem;
    @media (min-width: 992px) {
      font-size: 48px;
    }
  }
`

const Image = styled(Plaatjie)`
  @media (min-width: 1400px) {
    width: 660px;
  }
`

const Block = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.35);

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-family: ${({ theme }) => theme.font.family.heading};
    font-weight: 700;
    font-size: 28px;
  }

  & svg {
    width: 22px;
    height: 18px;
    margin-top: 0.7rem;
  }
`

const ArrowWrapper = styled.div<{ open: boolean }>`
  min-width: 22px;
  & svg path {
    ${(props) =>
      props.open &&
      css`
        fill: ${({ theme }) => theme.color.primary};
      `}
  }
`

interface AccordionProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Accordion
}

const Accordion: React.FC<AccordionProps> = ({ fields }) => {
  const [currentIndex, setCurrentIndex] = useState<number | null>(0)

  const arrowVariants = {
    open: {
      rotate: 90,
    },
    collapsed: {
      rotate: 0,
    },
  }

  const descriptionVariants = {
    open: {
      opacity: 1,
      height: 'auto',
      overflow: 'hidden',
    },
    collapsed: {
      opacity: 0,
      height: 0,
      overflow: 'hidden',
    },
  }

  return (
    <section className="mb-5" id={fields.sectionid}>
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="row">
              <div className="col-md-6 position-relative">
                <Content content={fields.description} className="pe-md-5" />
                <Image image={fields.image} alt="" />
              </div>
              <div className="col-md-6">
                <Block className="position-relative p-md-5 p-3">
                  {fields.items?.map((item, index: any) => {
                    const open: boolean = currentIndex === index

                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <React.Fragment key={index}>
                        <motion.div
                          className={`d-flex align-items-start justify-content-between ${
                            !open && 'mb-md-5 mb-3'
                          }`}
                          onClick={() =>
                            open
                              ? setCurrentIndex(null)
                              : setCurrentIndex(index)
                          }
                          role="button"
                        >
                          <h2>{item?.title}</h2>
                          <ArrowWrapper className="ms-auto" open={open}>
                            <motion.div
                              initial="collapsed"
                              animate={open ? 'open' : 'collapsed'}
                              variants={arrowVariants}
                            >
                              <ArrowRight />
                            </motion.div>
                          </ArrowWrapper>
                        </motion.div>
                        <AnimatePresence>
                          {open && (
                            <motion.div
                              initial="collapsed"
                              animate="open"
                              exit="collapsed"
                              variants={descriptionVariants}
                              transition={{
                                duration: 0.8,
                                ease: [0.04, 0.62, 0.23, 0.98],
                              }}
                            >
                              <ParseContent
                                content={item?.description}
                                className="py-4"
                              />
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </React.Fragment>
                    )
                  })}
                </Block>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Accordion
